import { ICarouselImage } from '@dex/models/carousel-image.model';
import { ILeague } from '@dex/models/league.model';

export interface ILeagueState {
  selectedLeague?: ILeague | null;
  news: {
    images: {
      list: ICarouselImage[];
      loading: boolean;
      error: any;
    };
  };
  list?: ILeague[];
  error: any;
  loading: boolean;
}

export const initialLeagueState: ILeagueState = {
  error: null,
  loading: false,
  news: {
    images: {
      list: [],
      loading: false,
      error: null,
    },
  },
};
